import {
    collection,
    CollectionReference,
    getDocs,
    getDoc,
    query,
    doc,
    updateDoc,
    DocumentData,
} from "firebase/firestore";
import FirebaseService from "./firebaseService";
import {IDateCardOrder} from "../types/DateCardOrders";
import { DateCardOrderStatus } from "../constants/DateCardOrderStatus";


class DateCardsService {
    ref: CollectionReference;

    constructor() {
        const db = FirebaseService.db;
        this.ref = collection(db, 'DateCardOrders');
    }

    async getDateCardOrders(): Promise<IDateCardOrder[]> {
        const q = query(this.ref);
        try {
            const docs = await getDocs(q);
           
            const orders = docs.docs.map(async (dateCardDoc) => {
                const data = dateCardDoc.data();
                return this._toOrders(data["Orders"], dateCardDoc.id);
            });
            return (await Promise.all(orders)).flat();
          } catch (error) {
            console.error("Error fetching documents: ", error);
          }
          return (await Promise.all([]));
    }

    async getDeliveredDateCardOrders(): Promise<IDateCardOrder[]> {
        const q = query(this.ref);
        try {
            const docs = await getDocs(q);
    
            const orders = docs.docs.map(async (dateCardDoc) => {
                const data = dateCardDoc.data();
                const allOrders = this._toOrders(data["Orders"], dateCardDoc.id);
    
                // Filter orders to only include those with the 'delivered' status
                return allOrders.filter(order => order.orderStatus === DateCardOrderStatus.delivered);
            });
    
            return (await Promise.all(orders)).flat();
        } catch (error) {
            console.error("Error fetching delivered orders: ", error);
        }
        return (await Promise.all([]));
    }

    async getPendingDateCardOrders(): Promise<IDateCardOrder[]> {
        const q = query(this.ref);
        try {
            const docs = await getDocs(q);
    
            const orders = docs.docs.map(async (dateCardDoc) => {
                const data = dateCardDoc.data();
                const allOrders = this._toOrders(data["Orders"], dateCardDoc.id);
    
                // Filter orders to only include those with the 'delivered' status
                return allOrders.filter(order => ![undefined, DateCardOrderStatus.default, DateCardOrderStatus.delivered].includes(order.orderStatus  as DateCardOrderStatus | undefined)); 
            });
    
            return (await Promise.all(orders)).flat();
        } catch (error) {
            console.error("Error fetching delivered orders: ", error);
        }
        return (await Promise.all([]));
    }

    async updateOrderStatus(orderId: string, dateCardDocId: string, newStatus: string) {
        const orderDocRef = doc(this.ref, dateCardDocId);
        console.log(newStatus);
        const dateCardDoc = await getDoc(orderDocRef);
        if (!dateCardDoc.exists()) {
            throw new Error("Document not found");
        }
    
        const orders = dateCardDoc.data()?.Orders || [];
        // Find the order by orderId and update its status
        const orderIndex = orders.findIndex((order: any) => order.orderID === orderId);
        if (orderIndex === -1) {
            throw new Error("Order not found");
        }
        
        orders[orderIndex].orderStatus = newStatus;
    
        // Update the entire orders array in Firestore
        await updateDoc(orderDocRef, { Orders: orders });
    }

    private _toOrders(snapshots: DocumentData, dateCardDocId: string): IDateCardOrder[] {
        return snapshots.map((doc: { data: () => IDateCardOrder; id: any; }) => {
            return { ...doc, dateCardDocId: dateCardDocId } as unknown as IDateCardOrder;
        });
    }

}

export default new DateCardsService();