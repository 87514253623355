import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer, IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import {FunctionComponent, PropsWithChildren, useState} from "react";
import {Person, Report, Menu, Event, AdminPanelSettings, PersonSearch, CardGiftcard} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";

const drawerWidth = 240;

const Layout: FunctionComponent<PropsWithChildren<{ signOut: () => void }>> = ({children, signOut}) => {
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <>
            <Toolbar/>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={'/reports'}
                                    selected={location.pathname.includes('reports')}>
                        <ListItemIcon>
                            <Report/>
                        </ListItemIcon>
                        <ListItemText primary={"Reported Users"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={'/users'} selected={location.pathname.includes('users')}>
                        <ListItemIcon>
                            <Person/>
                        </ListItemIcon>
                        <ListItemText primary={"All Users"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={'/user-review'}
                                    selected={location.pathname.includes('user-review')}>
                        <ListItemIcon>
                            <PersonSearch/>
                        </ListItemIcon>
                        <ListItemText primary={"Review Users"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={'/profile-verifications'}
                                    selected={location.pathname.includes('profile-verifications')}>
                        <ListItemIcon>
                            <AdminPanelSettings/>
                        </ListItemIcon>
                        <ListItemText primary={"Verify Profiles"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={'/activations'}
                                    selected={location.pathname.includes('activations')}>
                        <ListItemIcon>
                            <Event/>
                        </ListItemIcon>
                        <ListItemText primary={"Activations"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={'/date-cards'}
                                    selected={location.pathname.includes('date-cards')}>
                        <ListItemIcon>
                            <CardGiftcard/>
                        </ListItemIcon>
                        <ListItemText primary={"Review Date Cards"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={'/delivered-cards'}
                                    selected={location.pathname.includes('delivered-cards')}>
                        <ListItemIcon>
                            <CardGiftcard/>
                        </ListItemIcon>
                        <ListItemText primary={"Delivered Date Cards"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={'/pending-cards'}
                                    selected={location.pathname.includes('pending-cards')}>
                        <ListItemIcon>
                            <CardGiftcard/>
                        </ListItemIcon>
                        <ListItemText primary={"Pending Date Cards"}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    );

    return (
        <Box>
            <AppBar position={"fixed"} sx={{width: {sm: `calc(100% - ${drawerWidth}px)`}, ml: {sm: `${drawerWidth}px`}}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <Menu/>
                    </IconButton>
                    <Typography variant={"h3"} component={"div"} sx={{flexGrow: 1}}>
                        Skip Admin
                    </Typography>

                    <Button color={"inherit"} onClick={() => signOut()}>Log Out</Button>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Drawer
                variant={"temporary"}
                anchor={"left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
            >
                {drawer}
            </Drawer>

            <Box sx={{mt: 10, ml: {sm: 34, xs: 2}, mr: 2}}>
                {children}
            </Box>

        </Box>
    )
}

export default Layout;