import {
    Box, Card, Link as MuiLink,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    TextField // Added TextField for the search bar
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dateCardsService from "../../../services/dateCardsService";
import { IDateCardOrder } from "../../../types/DateCardOrders";
import { formatTimestamp } from "../../../utils/timestampFormatter";

export const DateCardsDeliveredList: React.FC<{
    pageSize?: 10 | 25 | 50 | 100;
}> = ({ pageSize = 25 }) => {
    const [page, setPage] = useState(0);
    const [dateCardOrders, setDateCardOrders] = useState<IDateCardOrder[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<IDateCardOrder[]>([]); // State for filtered orders
    const [searchTerm, setSearchTerm] = useState(""); // State for search input

    async function getDateCardOrders() {
        const dateCardOrders = await dateCardsService.getDeliveredDateCardOrders();
        setDateCardOrders(dateCardOrders);
        setFilteredOrders(dateCardOrders); // Initialize filtered orders
    }

    useEffect(() => {
        void getDateCardOrders();
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredOrders(dateCardOrders.filter(order =>
            order.profileUrl?.toLowerCase().includes(term)
        ));
    };

    return (
        <Box>
            <Typography variant={"h1"} sx={{ mb: 4 }}>
                Delivered Date Cards
            </Typography>
            {/* Search Bar */}
            <Box sx={{ mb: 4 }}>
                <TextField
                    label="Search by Profile URL"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearch}
                    sx={{ width: '300px' }}
                />
            </Box>

            <Card sx={{ mb: 4 }}>
                <Box sx={{ width: '100%' }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Color</TableCell>
                                    <TableCell>Card intro message</TableCell>
                                    <TableCell>Card name</TableCell>
                                    <TableCell>Order created</TableCell>
                                    <TableCell>Profile Url</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredOrders
                                    .sort((a, b) => {
                                        const dateA = a.timeStamp ? new Date(a.timeStamp).getTime() : new Date().getTime();
                                        const dateB = b.timeStamp ? new Date(b.timeStamp).getTime() : new Date().getTime();
                                        return dateA - dateB;
                                    }) // Sort by timestamp (oldest to newest)
                                    .slice(page * pageSize, page * pageSize + pageSize).map((row) => (
                                        <TableRow
                                            key={row.orderID}
                                            sx={{ cursor: 'default', textDecoration: 'none' }}
                                        >
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.address != null ? row.address : 'null'}</TableCell>
                                            <TableCell>{row.cardColor}</TableCell>
                                            <TableCell>{row.cardMessage}</TableCell>
                                            <TableCell>{row.cardName}</TableCell>
                                            <TableCell>{formatTimestamp(row.timeStamp)}</TableCell>
                                            <TableCell>
                                                <MuiLink
                                                    href={row.profileUrl}
                                                    onClick={(e) => e.stopPropagation()}
                                                    target={row.profileUrl}
                                                >
                                                    {row.profileUrl}
                                                </MuiLink>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component={"div"}
                        count={filteredOrders.length}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={(event, newPage) => setPage(newPage)}
                    />
                </Box>
            </Card>
        </Box>
    );
};